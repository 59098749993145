import { ref, onMounted } from 'vue';
import router from '@/routes/routes.js';
import axios from 'axios';
import {isArray, isEmpty, round, concat, sum, flatten, fill, cloneDeep, uniqBy} from 'lodash'
import { getParameterValue } from '@/store/services/traits/parameter.js';
import useModule from '@/composable/useModule.js'

export const useProjectData = (configuration = null) => 
{
    const route = router.currentRoute;
    const { prepareModuleProperties } = useModule();
    const { workspaceId } = route.params;
    const chartTypes = [
        {id: 'column', title: 'Column'},
        {id: 'line', title: 'Line'}
    ];
    const dataSources = [
        {id: 1, title: 'Scenario Properties'},
        {id:2, title: 'Assessment Properties'}
    ];
    const targetSource = [
        {id: 'module', title: 'Module data'},
        {id: 1, title: 'Group level 1'},
        {id: 2, title: 'Group level 2'},
        {id: 3, title: 'Group level 3'}
    ]

    const projects = ref([]);
    const scenarios = ref([]);
    const modules = ref([]);
    const properties = ref([]);
    const parameters = ref([]);
    const l1Data = ref([]);
    const l2Data = ref([]);
    const l3Data = ref([]);
    const assessments = ref([]);

    const getProjectsByWorkspace = async (workspaceId) => {
        projects.value = await axios.get(`workspaces/system/projects/${workspaceId}`).then(({ data }) => data.data);
    }

    const getScenariosByProject = async (projectId) => {
        let response = await axios.get(`communicators/system/${projectId}/scenarios`).then(({ data }) => data.data);
        scenarios.value = response
        getModulesByProject(projectId);
        return response
    }

    const getModulesByProject = async (projectId) => {
        let response = await axios.get(`workspaces/system/modules/${projectId}`).then(({ data }) => data.data);
        modules.value = response;
        return response;
    }


    const getLevelDataByScenarioAndModule = async (scenarioId, moduleId, params = null) => {
        const localParams = {
            params: {
                module_id: moduleId,
                response_type: 'nested',
                dashboard: true,
                ...params
            }
        }
        let response = await axios.get(`communicators/system/${scenarioId}/a-level-data`, localParams).then(({ data }) => data.data);
        l1Data.value = response;
        return response;
    }
    const getSecondLevelData = (id) => {
        let { child } = l1Data.value.find(item => item.id == id);
        l2Data.value = child || [];
    }

    const getThirdLevelData = (id) => {
        let { child } = l2Data.value.find(item => item.id == id);
        l3Data.value = child || [];
    }

    // Assessments api
    const getAssessmentDataByScenarioAndModule = async (scenario_id, module_id) => {
        let localParams = {
            params: {
                scenario_id,
                module_id
            }
        }
        let response = await axios.get(`projects/assessments/system/scenariowise`, localParams).then(({data}) => data.data)
        assessments.value = response
    }

    const getAssessmentDataByAssessMentAndParameter = async (assessmentId, parameterId) => {
        return await axios.get(`workspaces/system/assessments/${assessmentId}`,{params: { parameter_id: parameterId }}).then(({data}) => data.data)
    }

    // const getAssessmentDataById = async (assessmentId) => {
    //     return await axios.get(`workspaces/system/samples/${assessmentId}/values`).then(({data}) => data.data);
    // }

    // Properties api
    const getPropertiesByModule = async (moduleId) => {
        let response = await axios.get(`/properties/manage/${moduleId}`).then(({ data }) => data.data);
        properties.value  = response;
        return response;
    }

    const getParameterByProperty = (propertyId) => {
        let property = properties.value.find(item => item.id == propertyId);
        if(property) {
            parameters.value = property.property_collections;
            return property.property_collections;
        }
    }

    const getInputValue = (input) => {
        if(configuration && configuration.form && configuration.form[input]) {
            return configuration.form[input]
        }
    }

    if(configuration && configuration.mountable) {
        onMounted(async () => {
            if(workspaceId) {
                getProjectsByWorkspace(workspaceId);
            }
            if(getInputValue('project_id')) {
                getScenariosByProject(getInputValue('project_id'));
                getModulesByProject(getInputValue('project_id'))
            }

            if(getInputValue('scenario_id') && getInputValue('module_id')) {
               await getLevelDataByScenarioAndModule(getInputValue('scenario_id'), getInputValue('module_id'));
            }
            if(getInputValue('l1Data')) {
                getSecondLevelData(getInputValue('l1Data'));
            }
            if(getInputValue('l2Data')) {
                getThirdLevelData(getInputValue('l2Data'))
            }
            if(getInputValue('module_id')) {
               await getPropertiesByModule(getInputValue('module_id'));
            }
            if(getInputValue('property_id')) {
                getParameterByProperty(getInputValue('property_id'));
            }
            if(getInputValue('scenario_id') && getInputValue('module_id')) {
                getAssessmentDataByScenarioAndModule(getInputValue('scenario_id'), getInputValue('module_id'))
            }
        });
    }

    // Data manipulation
    const handleLevelData = async (config) => {
        let emptyItems = [];
        let emptyItems2 = [];
        let categories = [];
        let series = await config.forms.map(async (form, index) => await _getProjectData(form, index, config));
        let data = await Promise.all(series);

        data = data.filter(item => !!item == true)
        data.forEach((item, index) => {
            if(!isEmpty(item) && isArray(item)) {
                categories[index] = item[0]['data'].map(i => i.x);
                if(item[0].type == 'column' && config.chartType != "AssessmentChart") {
                     // Caution: Bellow codes ordering is important
                    let empties = fill(Array(sum(emptyItems)), {x: '', y: null});
                    emptyItems[index] = item[0]['data'].length;
                    item.forEach(i => i.data = concat(empties, i.data));
                }

                if(item[0].type == 'line' && config.chartType != "AssessmentChart") {
                    // Caution: Bellow codes ordering is important
                    let empties2 = fill(Array(sum(emptyItems2)), {x: '', y: null});
                    emptyItems2[index] = item[0]['data'].length;
                    item.forEach(i => i.data = concat(empties2, i.data));
                }
            }
        });

        data = flatten(data);
        if(isArray(data)) {
            return {
                series: data.map(i =>  !isEmpty(i) ? i : {name: 'Default', data: []}),
                categories: flatten(categories)
            }
        }
    }
    const handleParameterData = async (form) => {
        let data = await _getProjectData(form, 0);
        if(!data) {
            return [
                {
                    name: 'Default',
                    type: 'line',
                    data: []
                }
            ]
        }
        return data;
    }

    const _getTargetLevel = (form) => {
        if(form.level_4) return 4;
        if(form.level_3) return 3;
        if(form.level_2) return 2;
        if(form.level_1) return 1;
    }

    // Parameter presentation start
    const _getProjectData = async (form, index = null, config=null) => {
        if(!_hasParameter(form.properties)) return

        let properties = await getPropertiesByModule(form.module_id);
        let levelSource = form.l3Data || form.l2Data || form.l1Data || null;

        let query = {
            with_parameter: true,
            level_id: levelSource
        }
        let collections = [];
        if(form.assessment_id && _hasParameter(form.properties)) {
            let parameter_id = form.properties[0].parameters.find(item => item.parameter_id != null)?.parameter_id
            if(!parameter_id) return
            collections = await getAssessmentDataByAssessMentAndParameter(form.assessment_id, parameter_id);
            
            // collection filter start
            if(!isEmpty(form.filterTreeData)){
                collections = collections.filter(item => {
                    if(item.id && form.filterTreeData.includes(item.id.toString())){
                        return item
                    }
                })
            }
            // collection filter end

            return _handleAssessmentData(form, collections, config)
        } else {
            let targetLevel = _getTargetLevel(form);
            let localCollections = await getLevelDataByScenarioAndModule(form.scenario_id, form.module_id, query);
            collections = cloneDeep(localCollections);
            
            // localCollections.splice(0, 1);
            let nestedCollections = collectionFlatten(localCollections, targetLevel);
            nestedCollections.unshift(collections[0]);
            collections = nestedCollections;

            // collection filter start
            if(!isEmpty(form.filterTreeData)){
                collections = collections.filter(item => {
                    if(item.id && form.filterTreeData.includes(item.id.toString())){
                        return item
                    }
                })
            }
            // collection filter end
        }

        

        if(levelSource) {
            if(form.level_1 && collections && collections.length > 0) {
                let { child = [] } = collections[0];
                return _handleProperties(form, properties, concat(collections, child), index);
            }
            return _handleProperties(form, properties, collections, index);
        }
        if(form.module_id) {
            let module = {
                id: form.module_id,
                title: '',
                system_properties: properties
            }
            module = prepareModuleProperties(module, collections);

            if(form.level_1) {
                if(isArray(collections) && isEmpty(module.properties)) {
                    collections.unshift(module);
                }
                return _handleProperties(form, properties, collections, index);
            }
            return _handleProperties(form, properties, [module], index);
        }
    }

    const _hasParameter = (properties) => {
        let hasParameter = false
        properties.forEach(property => {
            property.parameters.forEach(parameter => {
                if(parameter.id){
                    hasParameter = true
                }
            })
        })

        return hasParameter
    }

    // Helpers for data manipulation
    const _handleAssessmentData = (form, collections, config=null) => {
        // series
        let data = []
        
        collections.forEach(item => {
            if(item.level<=getSelectedLevel(form)){
                let nameSuffix = ''
                if(config.chartType == "AssessmentChart"){
                    let selectedParameter = form.properties.map(item => {
                        return item.parameters.find(parameter => parameter.parameter_id)
                    })
                    nameSuffix = `(${selectedParameter[0].title})`
                }
                
                let series = {
                    name: `${item.title} ${nameSuffix}`,
                    axis: form.axis,
                    type: form.chartTypes,
                    color: item.color,
                    data: _makeAssessmentResponse(item.properties, config)
                }
                data.push(series)
            }
        })

        data.unshift({
                name: collections[0].title,
                axis: form.axis,
                type: form.chartTypes,
                color: collections[0].color,
                data: _makeAssessmentResponse(collections[0].properties, config)
        })
        return data
    }

    const getSelectedLevel = (form) => {
        let level;
        for(let i = 1; i < 5; i++){
            level = (form[`level_${i}`]) ? i : level
        }
        return level
    }

    const _makeAssessmentResponse = (properties, config=null) => {
        let data = []
        if(config.horizontalCategoryType=='month'){
            properties.forEach(item => {
                if(item.value_by_month != null){
                    data.push({
                        x: item.x_axis_by_month,
                        y: item.value_by_month,
                    })
                }
            })
        }

        if(config.horizontalCategoryType=='date'){
            properties.forEach(item => {
                data.push({
                    x: item.x_axis_by_date,
                    y: item.value,
                })
            })
        }
        
        return data
    }

    const _handleProperties = (form, properties, collections, index = null) => {
        let parameters = [];
        form.properties.forEach(property => {
            parameters = property.parameters.filter(item => Boolean(item.parameter_id)).map(parameter => ({
                name: _getParameterTitle(properties, property, parameter),
                axis: form.axis,
                type: form.chartTypes,
                color: parameter.color,
                data: collections.map(item => _makeResponse(item, parameter, index))
            }))
        });
        return parameters;
    }

    const _makeResponse = (item, parameter, index = null) => {
        return {
            x: `${item.title}`,
            y: round(getParameterValue(item, {id: parameter.parameter_id}), 2)
        }
    }

    const _getParameterTitle = (properties, property, parameter) => {
        if(properties && properties.length > 0) {
            let localProperty = properties.find(item => item.id == property.property_id);
            if(localProperty && localProperty.property_collections && localProperty.property_collections.length > 0) {
                let localParameter = localProperty.property_collections.find(item => item.id == parameter.parameter_id);
                if(localParameter) {
                    return parameter.title
                }
            }
        }
        return 'Default'
    }

    const collectionFlatten = (items, targetLevel = 2, selfLevel = 1) => {
        let flatten = (arr) => {
            let data = [];
            if(!arr) {
                return
            }
            arr.forEach((item) => recursiveFlatten(item, targetLevel, selfLevel));
            function recursiveFlatten(item, targetLevel, selfLevel) {
                if(targetLevel >= selfLevel) {
                    data.push(item);
                    if (item.child && item.child.length > 0) {
                        selfLevel += 1;
                        item.child.forEach((child) => {
                            recursiveFlatten(child, targetLevel, selfLevel);
                        });
                    }
                }
            }
            return data;
        }
        return uniqBy(flatten(items), 'id');
    }

    const handleLevel = (form, currentLevel, checked) => {
        const levels = [
            'level_1',
            'level_2',
            'level_3',
            'level_4'
        ]

        let isChecked = true
        levels.forEach(item => 
        {
            if(item == currentLevel && !checked) isChecked = false
            form[item] = isChecked
            if(item == currentLevel && checked) isChecked = false
        })
    }

    return {
        chartTypes,
        dataSources,
        targetSource,
        projects,
        scenarios,
        modules,
        l1Data,
        l2Data,
        l3Data,
        assessments,
        properties,
        parameters,
        handleLevelData,
        getSecondLevelData,
        getThirdLevelData,
        handleParameterData,
        handleLevel,
        getProjectsByWorkspace,
        getLevelDataByScenarioAndModule,
        getScenariosByProject,
        getModulesByProject,
        getPropertiesByModule,
        getParameterByProperty,
        getAssessmentDataByScenarioAndModule,
    }
}
